import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import App from "./App";
import {
  app as firebase,
  FirebaseContext,
  firebaseContextDefaultValue,
} from "./config/firebase";

firebase.auth().onAuthStateChanged(() => {
  ReactDOM.render(
    <React.StrictMode>
      <FirebaseContext.Provider value={firebaseContextDefaultValue}>
        <App />
      </FirebaseContext.Provider>
    </React.StrictMode>,
    document.getElementById("root")
  );
});
