import React from "react";
import { Field, FieldProps, FieldInputProps, ErrorMessage } from "formik";

type RadioOptionProps = {
  value: string;
  label: string;
};

type Props = { name: string; options: RadioOptionProps[] };

export default ({ name, options }: Props) => {
  return (
    <Field
      name={name}
      render={({ field }: FieldProps) => (
        <>
          {options.map((option) => (
            <RadioOption key={option.label} {...option} field={field} />
          ))}
          <ErrorMessage name={name}>
            {(error) => (
              <span
                className="error"
                style={{ float: "none", display: "block" }}
              >
                {error}
              </span>
            )}
          </ErrorMessage>
        </>
      )}
    />
  );
};

function RadioOption({
  field,
  value,
  label,
}: RadioOptionProps & { field: FieldInputProps<any> }) {
  return (
    <label>
      <input
        {...field}
        type="radio"
        value={value}
        style={{ display: "inline-block", width: "auto" }}
        checked={field.value === value}
      />
      {label}
    </label>
  );
}
