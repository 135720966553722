import React from "react";
import { Aanmelding } from "../../AanmeldingsFormulier/Aanmelding";
import { Document, View, Text } from "@react-pdf/renderer";
import Section from "./Section";
import StyledPage from "./StyledPage";
import Field from "./Field";
import Schoolloopbaan, { colStyle, rowStyle } from "./Schoolloopbaan";

type Props = {
  aanmelding: Aanmelding;
};

export default ({ aanmelding }: Props) => {
  return (
    <Document>
      <StyledPage>
        <Section title="Basisinformatie">
          <Field label="Campus" value={aanmelding.campus?.toUpperCase()} />
          <Field label="Jaar" value={aanmelding.jaar + "e"} />
          <Field label="Richting" value={aanmelding.richting} />
        </Section>

        <Section title="Gegevens van de leerling">
          <Field label="Familienaam" value={aanmelding.naam} />
          <Field label="Voornaam" value={aanmelding.voornaam} />
          <Field label="Geslacht" value={aanmelding.geslacht} />
          <Field label="Roepnaam" value={aanmelding.roepnaam} />
          <Field label="Geboortedatum" value={aanmelding.geboortedatum} />
          <Field label="Nationaliteit" value={aanmelding.nationaliteit} />
          <Field label="In België sinds" value={aanmelding.inBelgieSinds} />
          <Field label="Geboorteplaats" value={aanmelding.geboorteplaats} />
          <Field label="Geboorteland" value={aanmelding.geboorteland} />
          <Field label="Rijksregisternummer" value={aanmelding.rijksregisternummer} />
          <Field label="Straat" value={aanmelding.straat} />
          <Field label="Huisnummer" value={aanmelding.huisnummer} />
          <Field label="Bus" value={aanmelding.bus} />
          <Field label="Postcode" value={aanmelding.postcode} />
          <Field label="Gemeente" value={aanmelding.gemeente} />

          <Field label="Telefoon thuis" value={aanmelding.telefoonThuis} />
          <Field label="GSM leerling" value={aanmelding.gsmLeerling} style={{ marginBottom: "12pt" }} />
          
          <Field label="GSM 1 contact" value={aanmelding.gsm1Type} />
          <Field label="GSM 1 nummer" value={aanmelding.gsm1} style={{ marginBottom: "12pt" }} />
          
          <Field label="GSM 2 contact" value={aanmelding.gsm2Type} />
          <Field label="GSM 2 nummer" value={aanmelding.gsm2} style={{ marginBottom: "12pt" }} />
          
          <Field label="GSM 3 contact" value={aanmelding.gsm3Type} />
          <Field label="GSM 3 nummer" value={aanmelding.gsm3} style={{ marginBottom: "12pt" }} />
          
          <Field label="GSM andere relatie" value={aanmelding.gsmAndereRelatie} />
          <Field label="GSM andere naam" value={aanmelding.gsmAndereNaam} />
          <Field label="GSM andere nummer" value={aanmelding.gsmAndere} style={{ marginBottom: "12pt" }} />
          
          <Field label="E-mail leerling" value={aanmelding.emailLeerling} />
          <Field label="E-mail vader" value={aanmelding.emailVader} />
          <Field label="E-mail moeder" value={aanmelding.emailMoeder} />
          <Field label="Andere e-mail" value={aanmelding.emailAndere} />
        </Section>
      </StyledPage>
      <StyledPage>
        <Section title="Informatie over het gezin">
          <Field widthPercent={50} label="Familienaam vader" value={aanmelding.familienaamVader} />
          <Field widthPercent={50} label="Voornaam vader" value={aanmelding.voornaamVader} />
          <Field widthPercent={50} label="Familienaam moeder" value={aanmelding.familienaamMoeder} />
          <Field widthPercent={50} label="Voornaam moeder" value={aanmelding.voornaamMoeder} />

          <Field widthPercent={50} label="Aantal jongens in het gezin" value={aanmelding.aantalJongensGezin + ""} />
          <Field widthPercent={50} label="Aantal meisjes in het gezin" value={aanmelding.aantalMeisjesGezin + ""} />
          <Field widthPercent={50} label="Plaats van de leerling in het gezin" value={aanmelding.plaatsLeerlingGezin} />
          <Field widthPercent={50} label="Naam broer(s) zus(sen) op onze school" value={aanmelding.naamBroersZussenScheppers} />
          <Field widthPercent={50} label="Leerling verblijft bij" value={aanmelding.leerlingVerblijftBij} />
          <Field widthPercent={50} label="Met welke gebeurtenissen moeten we rekening houden" value={aanmelding.gebeurtenissen} />
          <Field widthPercent={50} label="Ontvangt schooltoeslag" value={aanmelding.ontvangtSchooltoeslag ? "Ja" : "Nee"} />
          <Field widthPercent={50} label="Moeder heeft een diploma secundair" value={aanmelding.moederDiplomaSecundair === "ja" ? "Ja, heeft een diploma secundair." : "Nee, heeft geen diploma secundair"} />

          <Field widthPercent={50} label="Thuistaal met vader" value={aanmelding.thuistaalMetVader} />
          <Field widthPercent={50} label="Thuistaal met moeder" value={aanmelding.thuistaalMetMoeder} />
          <Field widthPercent={50} label="Thuistaal met broers/zussen" value={aanmelding.thuistaalMetBroersZussen} />
        </Section>

        <Section title="Aandachtspunten">
          <Field widthPercent={50} label="Is er een gemotiveerd verslag dat aanleiding kan geven tot inschrijving onder ontbindende voorwaarden?" value={aanmelding.gemotiveerdVerslagOntbinding} />
          <Field widthPercent={50} label="Reden" value={aanmelding.gemotiveerdVerslagOntbindingReden} />

          <Field widthPercent={50} label="Zijn er aandachtspunten rond de gezondheid van uw kind? " value={aanmelding.aandachtGezondheid} />
          <Field widthPercent={50} label="Heeft uw kind één of meerdere leerstoornissen? " value={aanmelding.aandachtLeerstoornissen} />
          <Field widthPercent={50} label="Welke" value={aanmelding.leerstoornissen?.join(", ")} />
          <Field widthPercent={50} label="Is er een attest voor deze leerstoornissen?" value={aanmelding.leerstoornissenGeattesteerd} />
          <Field widthPercent={50} label="Als uw kind een leerstoornis heeft, mag het GOK-team hierover contact met u opnemen?" value={aanmelding.aandachtGOKContact} />
          <Field widthPercent={50} label="Andere aandachtspunten" value={aanmelding.aandachtAndere} />
        </Section>
      </StyledPage>

      <StyledPage>
        <Section title="Opmerkingen van de leco bij deze inschrijving">
          <Field label={undefined} value={aanmelding.lecoOpmerkingen} />
        </Section>

        <Section title="Toestemmingen">
          <Field
            widthPercent={50}
            label="Voor een goede begeleiding van uw kind schakelt de school soms het CLB in. Dit kan te maken hebben met gezondheid, een leerstoornis,... Geeft u hiervoor de toestemming?"
            value={aanmelding.toelatingCLBDelen}
          />
        </Section>

        <Section title="Varia">
          <Field
            widthPercent={50}
            label="Hoe hebben de ouders de school leren kennen?"
            value={aanmelding.oudersSchoolLerenKennen}
          />

          <Field
            widthPercent={50}
            label="Andere manier"
            value={aanmelding.oudersSchoolLerenKennenAndere}
          />
        </Section>
      </StyledPage>

      <StyledPage orientation="landscape">
        <Section title="Schoolloopbaan">
          <Schoolloopbaan loopbaan={aanmelding.schoolloopbaan} />
        </Section>

        <Section title="Handtekeningen">
          <View style={{ ...rowStyle, fontFamily: "Helvetica-Bold", textAlign: "center" }}>
            <Text style={colStyle}>Vader</Text>
            <Text style={colStyle}>Moeder</Text>
            <Text style={colStyle}>Leerling</Text>
            <Text style={colStyle}>Andere (+ naam en relatie tot leerling)</Text>
          </View>

          <View style={{ ...rowStyle, minHeight: "50pt" }}>
            <Text style={colStyle}>&nbsp;</Text>
            <Text style={colStyle}>&nbsp;</Text>
            <Text style={colStyle}>&nbsp;</Text>
            <Text style={colStyle}>&nbsp;</Text>
          </View>
        </Section>
      </StyledPage>
    </Document>
  );
};
