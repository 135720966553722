import React from "react";
import ReactPDF, { Text, View } from "@react-pdf/renderer";
import { SchoolloopbaanJaar } from "../../AanmeldingsFormulier/Aanmelding";

type Props = {
  loopbaan: SchoolloopbaanJaar[];
};

export const rowStyle: ReactPDF.Style = {
  display: "flex",
  flexDirection: "row",
};

export const colStyle: ReactPDF.Style = {
  flex: 1,
  border: "1pt solid #ccc",
  padding: "5pt",
};

function Row(jaar: SchoolloopbaanJaar) {
  return (
    <View style={rowStyle}>
      <Text style={colStyle}>{jaar.schooljaar}</Text>
      <Text style={colStyle}>{jaar.gevolgdJaar}</Text>
      <Text style={colStyle}>{jaar.studierichting}</Text>
      <Text style={colStyle}>{jaar.school}</Text>
      <Text style={colStyle}>{jaar.attest}</Text>
    </View>
  );
}

export default ({ loopbaan }: Props) => {
  return (
    <View>
      <View style={{ ...rowStyle, fontFamily: "Helvetica-Bold" }}>
        <Text style={colStyle}>Schooljaar</Text>
        <Text style={colStyle}>Gevolgd jaar</Text>
        <Text style={colStyle}>Studierichting</Text>
        <Text style={colStyle}>School</Text>
        <Text style={colStyle}>Attest</Text>
      </View>

      {loopbaan.map((jaar) => (
        <Row {...jaar} key={jaar.schooljaar} />
      ))}
    </View>
  );
};
