import React from "react";
import { Aanmelding } from "./Aanmelding";
import MultiSelect from "./MultiSelect";
import RadioButton from "./RadioButton";
import InputField from "./InputField";

type Props = { values: Aanmelding };

export default (props: Props) => {
  return (
    <>
      <div className="itemMargin">
        Welke?
        <MultiSelect
          name="leerstoornissen"
          options={[
            {
              value: "dyslexie",
              label: "Dyslexie",
            },
            {
              value: "dyscalculie",
              label: "Dyscalculie",
            },
            {
              value: "dysorthografie",
              label: "Dysorthografie"
            },
            {
              value: "ass",
              label: "ASS"
            },
            {
              value: "dyspraxie",
              label: "Dyspraxie"
            },
          ]}
        />
      </div>

      <div className="itemMargin">
        Is er een attest voor deze leerstoornissen?
        <RadioButton
          name="leerstoornissenGeattesteerd"
          options={[
            {
              value: "ja",
              label: "Ja",
            },
            {
              value: "nee",
              label: "Nee",
            }
          ]}
        />
      </div>

      <div className="itemMargin">
        Als uw kind een leerstoornis heeft, mag het GOK-team hierover contact met u opnemen?
        <RadioButton
          name="aandachtGOKContact"
          options={[
            {
              value: "ja",
              label: "Ja",
            },
            {
              value: "nee",
              label: "Nee",
            }
          ]}
        />
      </div>

      {props.values.aandachtGOKContact === "ja"
        ? (
          <InputField
            name="aandachtGOKContactWie"
            component="textarea"
            label="Met wie mag het GOK-team contact opnemen in verband met de leerstoornis van uw kind? (naam + telefoonnummer of e-mailadres)"
          />
        )
        : null
      }

    </>
  );
};