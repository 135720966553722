import React, { useContext } from "react";
import { FirebaseContext } from "../../config/firebase";
import StyledFirebaseAuth from "react-firebaseui/StyledFirebaseAuth";
import { Redirect } from "react-router-dom";
import UserContext from "../Auth/UserContext";

export default () => {
  const { user, adminUser } = useContext(UserContext);
  const { auth, authConfig } = useContext(FirebaseContext);

  if (adminUser) {
    return <Redirect to="/aanmeldingen" />;
  }

  console.log(user);
  console.log(user?.email);

  return (
    <div>
      <h1 style={{ textAlign: "center" }}>Aanmelden</h1>
      <StyledFirebaseAuth uiConfig={authConfig} firebaseAuth={auth} />
    </div>
  );
};
