import React, { ComponentProps } from "react";
import {
  Text,
  View
} from "@react-pdf/renderer";

type Props = {
  title: string;
} & ComponentProps<any>;

export default ({ title, children }: Props) => {
  return (
    <View>
      <Text style={{
        fontSize: "20pt",
        fontWeight: "bold",
        textAlign: "center",
        backgroundColor: "#ccc",
        padding: "5pt"
      }}>
        {title}
      </Text>

      <View style={{ margin: "12pt 0" }}>
        {children}
      </View>
    </View>
  );
}