import React from "react";
import SelectBox from "./SelectBox"
import InputField from "./InputField";

type Props = {
  name: string;
  required?: boolean;
}

export default ({ required, name }: Props) => {
  return (
    <>
      <label className="selectBox">
        <span>Dit is het gsm-nummer van:</span>
        {required ? <span style={{ color: "red" }}>*</span> : null}

        <SelectBox
          name={`${name}Type`}
          options={[
            {
              value: "moeder",
              label: "moeder",
            },
            {
              value: "vader",
              label: "vader",
            },
            {
              value: "voogd",
              label: "voogd",
            },
            {
              value: "grootouders",
              label: "grootouders",
            },
            {
              value: "pleegouders",
              label: "pleegouders",
            },
            {
              value: "andere",
              label: "andere",
            }
          ]}
        />

        <InputField name={name} />
      </label>
    </>
  );
}