import React, { ComponentProps } from "react";
import ReactPDF, {
  Page
} from "@react-pdf/renderer";

type Props = ComponentProps<any> & ReactPDF.PageProps;

export default (props: Props) => {
  return (
    <Page
      size="A4"
      style={{
        padding: "40pt",
        fontSize: "12pt",
        fontFamily: "Helvetica"
      }}
      {...props}>
      {props.children}
    </Page>
  );
}