import React from "react";
import { Field, FieldProps, FieldInputProps, FormikProps } from "formik";

type SelectOptionProps = {
  value: string;
  label: string;
};

type Props = { name: string; options: SelectOptionProps[] };

export default ({ name, options }: Props) => {
  return (
    <Field
      name={name}
      render={({ field, form }: FieldProps) => (
        <select
          value={field.value}
          onChange={(e) =>
            form.setFieldValue(field.name, e.currentTarget.value)
          }
        >
          {options.map((option) => (
            <SelectOption
              {...option}
              key={option.label}
              field={field}
              form={form}
            />
          ))}
        </select>
      )}
    />
  );
};

function SelectOption({
  field,
  value,
  label,
  form,
}: SelectOptionProps & {
  field: FieldInputProps<any>;
  form: FormikProps<any>;
}) {
  return (
    <option {...field} value={value}>
      {label}
    </option>
  );
}
