import React from "react";
import { Field, ErrorMessage } from "formik";

type Props = {
  label?: string;
  name: string;
  type?: string;
  component?: string;
  required?: boolean;
} & { [key: string]: any };

export default ({ label, name, type, component, required, ...rest }: Props) => {
  if (!name && !label) {
    throw Error("Either name or label mustset");
  }

  const sanitizedName = name || (label || "").toLowerCase();

  return (
    <label style={rest.style}>
      <span className="label">
        {label} {required ? <span style={{ color: "red" }}>*</span> : null}
      </span>
      <Field
        name={sanitizedName}
        type={type || "text"}
        component={component}
        {...rest}
      />
      <ErrorMessage name={sanitizedName}>
        {(error) => <span className="error">{error}</span>}
      </ErrorMessage>
    </label>
  );
};
