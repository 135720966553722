import React, { useContext } from "react";
import { RouteComponentProps } from "react-router-dom";
import { FirebaseContext } from "../../config/firebase";
import { useObjectVal } from "react-firebase-hooks/database";
import AanmeldingsFormulier from "../AanmeldingsFormulier";
import Loader from "../Loader";
import { Aanmelding } from "../AanmeldingsFormulier/Aanmelding";

type Props = RouteComponentProps<{
  id: string;
}>;

export default ({
  match: {
    params: { id },
  },
}: Props) => {
  const { database } = useContext(FirebaseContext);
  const [aanmelding, loading, error] = useObjectVal<Aanmelding>(
    database.ref(`/aanmeldingen/${id}`),
    { keyField: "id" }
  );

  if (loading) {
    return <Loader />;
  }

  if (error) {
    return <div>Een fout is opgetreden: {JSON.stringify(error)}</div>;
  }

  if (!aanmelding) {
    return <div>Deze aanmelding bestaat niet</div>;
  }

  return <AanmeldingsFormulier aanmelding={aanmelding as Aanmelding} />;
};
