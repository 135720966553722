import React from "react";
import ReactPDF, {
  Text,
  View
} from "@react-pdf/renderer";

type Props = {
  label: string | undefined;
  value: string | undefined;
  widthPercent?: number;
  style?: ReactPDF.Style;
};

export default ({ label, value, widthPercent, style }: Props) => {
  widthPercent = widthPercent || 30;
  return (
    <View style={{ flexDirection: "row", marginBottom: "5pt", ...style }}>
      {label ? (
        <Text style={{ fontFamily: "Helvetica-Bold", width: `${widthPercent}%` }}>
          {label}:&nbsp;
        </Text>
      ) : null}

      <Text style={{width: `${100 - widthPercent}%`}}>
        {value}
      </Text>
    </View>
  );
}