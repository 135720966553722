import React, { useContext } from "react";
import { RouteProps, Route, Redirect } from "react-router-dom";
import UserContext from "./Auth/UserContext";

export default ({ component: Componentx, ...rest }: RouteProps) => {
  const { adminUser } = useContext(UserContext);
  const c = Componentx as any;

  return (
    <Route
      {...rest}
      render={(props) => {
        if (!adminUser) {
          return <Redirect to="/login" />;
        } else {
          return React.createElement(c, props);
        }
      }}
    />
  );
};
