import { sortBy } from "lodash";
import React from "react";
import { Aanmelding } from "../../AanmeldingsFormulier/Aanmelding";
import AanmeldingRow from "../AanmeldingRow";
import moment from "moment";

type Props = {
  jaar: number;
  aanmeldingen: Aanmelding[];
  deleteAanmelding: (aanmeldingId: string) => void;
};

export default ({ jaar, aanmeldingen, deleteAanmelding }: Props) => {
  const sortedAanmeldingen = sortBy(aanmeldingen, (x) => moment(x.date).format("YYYY-MM-DD"));
  
  return (
    <>
      <h2>{jaar}e jaar ({sortedAanmeldingen.length} aanmeldingen)</h2>

      <table>
        <thead>
          <tr>
            <th>Wanneer</th>
            <th>Voornaam</th>
            <th>Achternaam</th>
            <th>Jaar &amp; richting</th>
            <th>Print</th>
            <th>Bewerken</th>
            <th>Verwijderen</th>
          </tr>
        </thead>
        <tbody>
          {sortedAanmeldingen.map((aanmelding) => (
            <AanmeldingRow
              aanmelding={aanmelding}
              deleteAanmelding={deleteAanmelding}
            />
          ))}
        </tbody>
      </table>
    </>
  );
}