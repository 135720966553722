import React from "react";
import firebase from "firebase/app";
import "firebase/database";
import "firebase/auth";

const config = {
  apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
  authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
  databaseURL: process.env.REACT_APP_FIREBASE_DATABASE_URL,
  projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
  storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGE_SENDER_ID,
  appId: process.env.REACT_APP_FIREBASE_APP_ID,
  azureTenant: process.env.REACT_APP_AZURE_TENANT,
};

export const app = firebase.initializeApp(config);

// https://firebase.google.com/docs/auth/web/firebaseui#oauth_providers_google_facebook_twitter_and_github
export const authConfig: firebaseui.auth.Config = {
  signInSuccessUrl: "/aanmeldingen",
  signInOptions: [{
    provider: "microsoft.com",
    customParameters: {
      prompt: "select_account"
    }
  }],
};

export const firebaseContextDefaultValue = {
  database: app.database(),
  auth: app.auth(),
  authConfig
};

export const FirebaseContext = React.createContext(firebaseContextDefaultValue);
