import React from "react";
import { Field, FieldProps, FieldInputProps, ErrorMessage } from "formik";

type MultiSelectProps = {
  value: string;
  label: string;
};

type Props = { name: string; options: MultiSelectProps[] };

export default ({ name, options }: Props) => {
  return (
    <Field
      name={name}
      render={({ field }: FieldProps) => (
        <>
          {options.map((option) => (
            <MultiSelectOption {...option} field={field} />
          ))}
          <ErrorMessage name={name}>
            {(error) => <span className="error">{error}</span>}
          </ErrorMessage>
        </>
      )}
    />
  );
};

function MultiSelectOption({
  field,
  value,
  label,
}: MultiSelectProps & { field: FieldInputProps<string[]> }) {
  return (
    <label>
      <input
        {...field}
        type="checkbox"
        value={value}
        style={{ display: "inline-block", width: "auto" }}
        checked={(field.value || []).includes(value)}
      />
      {label}
    </label>
  );
}
