import React, { useState } from "react";
import { Aanmelding } from "../AanmeldingsFormulier/Aanmelding";
import moment from "moment";
import { PDFDownloadLink } from "@react-pdf/renderer";
import AanmeldingPdf from "./AanmeldingPdf";

type Props = {
  aanmelding: Aanmelding;
  deleteAanmelding: (aanmeldingId: string) => void;
};

export default ({ aanmelding, deleteAanmelding }: Props) => {
  const [renderPdf, setRenderPdf] = useState(false);

  return (
    <tr key={aanmelding.id}>
      <td>{moment(aanmelding.date).format("YYYY-MM-DD")}</td>
      <td>{aanmelding.voornaam}</td>
      <td>{aanmelding.naam}</td>
      <td>
        {aanmelding.jaar}e jaar {aanmelding.richting}
      </td>
      <td>
        {renderPdf ? (
          <PDFDownloadLink
            document={<AanmeldingPdf aanmelding={aanmelding} />}
            fileName={`${aanmelding.voornaam} ${aanmelding.naam}.pdf`}
          >
            {({ blob, url, loading, error }) => {
              return loading ? "Loading" : "Download pdf";
            }}
          </PDFDownloadLink>
        ) : (
          <button onClick={() => setRenderPdf(true)}>Maak pdf</button>
        )}
      </td>
      <td>
        <a href={`/aanmeldingen/${aanmelding.id}`}>Bewerken</a>
      </td>
      <td>
        <a
          href={`/delete/${aanmelding.id}`}
          onClick={e => {
            e.preventDefault();
            deleteAanmelding(aanmelding.id!);
          }}
        >
          Verwijderen
        </a>
      </td>
    </tr>
  );
};
