import React from "react";
import { FieldArray } from "formik";
import InputField from "./InputField";
import { Aanmelding, SchoolloopbaanJaar } from "./Aanmelding";

type Props = { values: Aanmelding };

function SchoolloopbaanYears({ loopbaan }: { loopbaan: SchoolloopbaanJaar[] }) {
  return (
    <>
      {loopbaan.map((schooljaar, i) => (
        <tr key={schooljaar.schooljaar}>
          <td>{schooljaar.schooljaar}</td>
          <td>
            <InputField name={`schoolloopbaan.${i}.gevolgdJaar`} />
          </td>
          <td>
            <InputField name={`schoolloopbaan.${i}.studierichting`} />
          </td>
          <td>
            <InputField name={`schoolloopbaan.${i}.school`} />
          </td>
          <td>
            <InputField name={`schoolloopbaan.${i}.attest`} />
          </td>
        </tr>
      ))}
    </>
  );
}

export default (props: Props) => {
  return (
    <table className="itemMargin">
      <thead>
        <tr>
          <th>Schooljaar</th>
          <th>Gevolgd jaar</th>
          <th>Studierichting</th>
          <th>School</th>
          <th>Attest</th>
        </tr>
      </thead>
      <tbody>
        <FieldArray
          name="schoolloopbaan"
          render={(arrayHelpers) => (
            <SchoolloopbaanYears loopbaan={props.values.schoolloopbaan} />
          )}
        />
      </tbody>
    </table>
  );
};